import React from 'react';
// do not use this example everywhere, no theming support in initial loading
import {combineStyle, useTheme, createUseStyles} from '../lib/theme';

const useStyles = createUseStyles({
    '@keyframes loading': {
        '0%': {
            top: '-1px',
            left: '-1px',
            width: '50px',
            height: '50px',
            opacity: 0,
        },
        '3%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            top: '23px',
            left: '23px',
            width: '2px',
            height: '2px',
            opacity: 0,
        }
    },
    wrapper: {
        display: 'block',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '48px',
        height: '48px',
        overflow: 'hidden'
    },
    item: {
        position: 'absolute',
        border: '2px solid #fff',
        opacity: '0',
        borderRadius: '50%',
        animation: 'loading 2.4s cubic-bezier(0, 0.3, 0.8, 1) infinite',
        '&:nth-child(2)': {
            animationDelay: '-1.2s',
        },
        '&:nth-child(3)': {
            animationDelay: '-0.8s',
        }
    }
});

const Loading = (props) => {
    // error is e.g. given from `Loadable` and is considered fatal error
    // errorWarning is is considered simple error
    const {
        error, errorWarning,
        absolute, center, name
    } = props;
    // todo: check useTheme because of no longer optional
    const theme = useTheme();
    const classes = useStyles(theme);

    let styled = {wrapper: {}, item: {}};
    if(theme) {
        // Loading can be loaded withTheme or just plain (e.g. when loading BaseApp where ThemeProvider is first set)
        styled.item.borderColor = theme.textColor;
    }

    if(absolute || center) {
        styled.wrapper.position = 'relative';
        styled.wrapper.left = '50%';
        styled.wrapper.transform = 'translateX(-50%)';
    }
    if(absolute) {
        styled.wrapper.position = 'absolute';
    }

    let style = {};
    if(props.style) {
        style.wrapper = props.style.wrapper || {};
        style.item = props.style.item || {};
    } else {
        style.wrapper = {};
        style.item = {};
    }

    if(errorWarning) {
        style.item.borderColor = theme.errorColor;
    }

    styled.wrapper = combineStyle(styled.wrapper, style.wrapper);
    styled.item = combineStyle(styled.item, style.item);

    return (
        error ? (
                <React.Fragment>
                    {props.children}
                    <p>Fatal Error on Loading Component {name}</p>
                    <p>{JSON.stringify(error)}</p>
                </React.Fragment>
            ) :
            <React.Fragment>
                {props.children}
                <div className={classes.wrapper} style={styled.wrapper}>
                    <span className={classes.item} style={styled.item}/>
                    <span className={classes.item} style={styled.item}/>
                    <span className={classes.item} style={styled.item}/>
                </div>
            </React.Fragment>
    )
};

const LoadingRaw = Loading;

export default Loading;

export {LoadingRaw};
